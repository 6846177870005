import { RequestError, RequestErrorType } from './generic-errors';
import { Cybersource } from './cybersource.type';

export function checkCybersourcePaymentValidation(cybersourceError: any) {
  if (cybersourceError) {
    const error = cybersourceError as Cybersource.CyberSourcePaymentError;
    const reason = error.reason || error.data?.errorInformation?.reason || Cybersource.PaymentErrorReason.GENERIC;

    switch (reason) {
      case Cybersource.PaymentErrorReason.EXCEEDS_CREDIT_LIMIT:
        throw new RequestError(RequestErrorType.PaymentExceedsCreditLimit, cybersourceError);
      case Cybersource.PaymentErrorReason.PROCESSOR_DECLINED:
        throw new RequestError(RequestErrorType.PaymentDeclined, cybersourceError);
      case Cybersource.PaymentErrorReason.EXPIRED_CARD:
        throw new RequestError(RequestErrorType.PaymentExpiredCard, cybersourceError);
      case Cybersource.PaymentErrorReason.INSUFFICIENT_FUND:
        throw new RequestError(RequestErrorType.PaymentInsufficientFund, cybersourceError);
      case Cybersource.PaymentErrorReason.INVALID_CVN:
        throw new RequestError(RequestErrorType.PaymentInvalidCvv, cybersourceError);
      case Cybersource.PaymentErrorReason.INVALID_DATA:
        throw new RequestError(RequestErrorType.PaymentInvalidData, cybersourceError);
      case Cybersource.PaymentErrorReason.PAYMENT_REFUSED:
        throw new RequestError(RequestErrorType.PaymentPaymentRefused, cybersourceError);
      case Cybersource.PaymentErrorReason.MISSING_FIELD:
        throw new RequestError(RequestErrorType.PaymentPaymentRefused, cybersourceError);
      case Cybersource.PaymentErrorReason.GENERIC:
        throw new RequestError(RequestErrorType.PaymentError, cybersourceError);
    }
  }
}
