import { Cybersource } from '../cybersource.type';

export function getTransactionId(paymentResponse: Cybersource.ExecutePaymentResponseObject | any | null) {
  const transactionId = paymentResponse?.response?.data?.processorInformation?.transactionId;

  if (!transactionId) {
    console.log('invalid payment response');
    return String(Math.random());
  }

  return transactionId;
}
